<template>
   <Layout>
      <template v-if="this.$route.name == 'add-live-event'">
         <PageHeader :title="title1" :items="items"></PageHeader>
      </template>
      <template v-if="this.$route.name == 'edit-live-event'">
         <PageHeader :title="title2" :items="items"></PageHeader>
      </template>
      <div class="row">
         <div class="col-12">
            <div class="card">
               <div class="card-body">
                  <form-wizard color="#556ee6" enctype="multipart/form-data" ref="liveEvent" @on-complete="submitData"
                     :finishButtonText="this.$route.name == 'add-live-event' ? 'Save Data' : 'Update Data'"
                     back-button-text="Go Back!" next-button-text="Go Next!">
                     <tab-content title="Live Event Details" icon="mdi mdi-account-details"
                        :before-change="() => validateFormOne()">
                        <div class="row">
                           <b-form-group class="col-6">
                              <label for="title-input">Live Event Title<span style="color: red"> *</span></label>
                              <b-form-input id="title-input" v-model="form.title" placeholder="Enter Live Event Title"
                                 :class="{ 'is-invalid': submitted && $v.form.title.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">Live Event Title
                                 is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="link-id">Link ID<span style="color: red"> *</span></label>
                              <b-form-input id="link-id" v-model="form.link_id" placeholder="Enter Link ID"
                                 :class="{ 'is-invalid': submitted && $v.form.link_id.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.link_id.required" class="invalid-feedback">Link ID is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-12" label-for="desc" label="Tell Us More About the Live Event">
                              <ckeditor v-model="form.description">
                              </ckeditor>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="session-date-time">Session Start Time <span style="color: red">
                                    *</span></label>
                              <b-form-input id="session-date-time" placeholder="Enter Session Date Time"
                                 v-model="form.session_time" type="datetime-local" :min="disabledPastDates()"
                                 :class="{ 'is-invalid': submitted && $v.form.session_time.$invalid }" @change="clearEndTime">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.session_time.required" class="invalid-feedback">Session
                                 Start Time is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="session-end-time">Session End Time <span style="color: red">
                                    *</span></label>
                              <b-form-input id="session-end-time" placeholder="Enter Session End Time"
                                 v-model="form.session_end_time" type="datetime-local" :min="form.session_time || disabledDates()"
                                 :class="{ 'is-invalid': submitted && $v.form.session_end_time.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.session_end_time.required" class="invalid-feedback">Session
                                 End Time is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-4">
                              <div class="d-flex justify-content-start align-items-center">
                                 <label for="time-zone">Time Zone</label>
                                 <div class="time_zone_badge ml-2 mb-2" v-b-tooltip.hover.html="timeZone" variant="info">Info</div>
                              </div>
                              <b-form-input id="time-zone" placeholder="Enter Time Zone (Leave it Blank for INDIA)"
                                 v-model="form.time_zone">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-4">
                              <div class="d-flex justify-content-start align-items-center">
                                 <label for="time-zone-code">Time Zone Code</label>
                                 <div class="time_zone_badge ml-2 mb-2" v-b-tooltip.hover.html="timeZoneCode" variant="info">Info</div>
                              </div>
                              <b-form-input id="time-zone-code" v-model="form.time_zone_code"
                                 placeholder="Enter Time Zone Code (Leave it Blank for INDIA)"></b-form-input>
                           </b-form-group>
                           <b-form-group class="col-4">
                              <label for="buffer-time">Buffer Time <span style="color: red">*</span></label>
                              <b-form-input id="buffer-time" type="number" min="0" placeholder="Enter Buffer time"
                                 v-model="form.buffer_time"
                                 :class="{ 'is-invalid': submitted && $v.form.buffer_time.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.buffer_time.required" class="invalid-feedback">Bufer Time
                                 is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-4" label="Banner Image" label-for="banner-img">
                              <b-form-file id="banner-img" accept="image/*" @change="readFile($event, 'banner_image')"
                                 ref="banner_image" placeholder="Choose a file [Upload Max File Size : 2MB]">
                              </b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.banner_image_url
                              ">
                                 <img :src="edit.banner_image_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="banner_image_url">
                                 <img :src="banner_image_url" width="128px" height="128px" ref="banner_image_url"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-4" label="Mobile Banner Image" label-for="mob-banner-img">
                              <b-form-file id="mob-banner-img" accept="image/*" ref="mobile_banner_image"
                                 @change="readFile($event, 'mobile_banner_image')"
                                 placeholder="Choose a file [Upload Max File Size : 2MB]"></b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.mobile_banner_image_url
                              ">
                                 <img :src="edit.mobile_banner_image_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="mobile_banner_image_url">
                                 <img :src="mobile_banner_image_url" width="128px" height="128px"
                                    ref="mobile_banner_image_url" style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-4" label="Thumbnail Image" label-for="thumbnail-img">
                              <b-form-file id="thumbnail-img" accept="image/*" ref="thumbnail_image"
                                 @change="readFile($event, 'thumbnail_image')"
                                 placeholder="Choose a file [Upload Max File Size : 2MB]"></b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.thumbnail_image_url
                              ">
                                 <img :src="edit.thumbnail_image_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="thumbnail_image_url">
                                 <img :src="thumbnail_image_url" width="128px" height="128px" ref="thumbnail_image_url"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-6" label="Register Image 1" label-for="reg-img-1">
                              <b-form-file id="reg-img-1" ref="register_image1" accept="image/*"
                                 @change="readFile($event, 'register_image1')"
                                 placeholder="Choose a file [Upload Max File Size : 2MB]"></b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.register_image1_url
                              ">
                                 <img :src="edit.register_image1_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="register_image1_url">
                                 <img :src="register_image1_url" width="128px" height="128px" ref="register_image1_url"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-6" label="Register Image 2" label-for="reg-img-2">
                              <b-form-file id="reg-img-2" ref="register_image2" accept="image/*"
                                 @change="readFile($event, 'register_image2')"
                                 placeholder="Choose a file [Upload Max File Size : 2MB]"></b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.register_image2_url
                              ">
                                 <img :src="edit.register_image2_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="register_image2_url">
                                 <img :src="register_image2_url" width="128px" height="128px" ref="register_image2_url"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-6" label="Medisage Live Event Moderator Password" label-for="pass">
                              <b-form-input id="pass" v-model="form.moderator_password"
                                 placeholder="Enter Medisage Live Event Moderator Password">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="live-event-txt">Live Event Text<span style="color: red">
                                    *</span></label>
                              <b-form-input id="live-event-txt" v-model="form.live_event_text"
                                 placeholder="Enter Live Event Text"
                                 :class="{ 'is-invalid': submitted && $v.form.live_event_text.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.live_event_text.required" class="invalid-feedback">Live
                                 Event Text is
                                 required.</div>
                           </b-form-group>
                           <b-form-group label="View Multiplication Factor" label-for="view_multi_factor"
                              class="col-3">
                              <b-form-input id="view_multi_factor" type="number"
                                 placeholder="Enter View Multiplication Factor"
                                 v-model="form.view_multiplication_factor">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-3" label="Certificate ID" label-for="cert">
                              <b-form-input id="cert" type="number" min="0" placeholder="Enter Certificate ID"
                                 v-model="form.certificate_id">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-3" label="Rewards" label-for="rew">
                              <b-form-input id="rew" type="number" min="0" step="0.1" v-model="form.rewards"
                                 placeholder="Enter Rewards"></b-form-input>
                           </b-form-group>
                           <b-form-group class="col-3" label="Email Link" label-for="email-link">
                              <b-form-input id="email-link" placeholder="Enter Email Link" v-model="form.email_link">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-6" label="External Event Link" label-for="ext">
                              <b-form-input id="ext" v-model="form.external_event"
                                 placeholder="keep empty for in house event"></b-form-input>
                           </b-form-group>
                           <b-form-group class="col-6" label="Redirect Event Link Without Registration"
                              label-for="ext2">
                              <b-form-input id="ext2" v-model="form.redirect_away_without_registration"
                                 placeholder="keep empty for in house event"></b-form-input>
                           </b-form-group>
                           <b-form-group class="col-6" label="Registration Password" label-for="pass1">
                              <b-form-input id="pass1" v-model="form.password" placeholder="keep empty to ignore">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-6" label="Registration Password Placeholder" label-for="pass2">
                              <b-form-input id="pass2" v-model="form.password_placeholder"
                                 placeholder="Enter Registration Password Placeholder">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-12" label="Chat Type" label-for="chat-type">
                              <b-form-radio-group v-model="form.chat_type" :options="chatType">
                              </b-form-radio-group>
                           </b-form-group>
                           <b-form-group class="col-6" label-for="terms-and-cond" label="Terms and Conditions">
                              <ckeditor v-model="form.tnc_detail">
                              </ckeditor>
                           </b-form-group>
                           <b-form-group class="col-6" label-for="cal-desc" label="Calendar Description">
                              <ckeditor v-model="form.calender_description">
                              </ckeditor>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="video-id">Video ID<span style="color: red"> *</span></label>
                              <b-form-input id="video-id" v-model="form.video_id" placeholder="Enter Video ID"
                                 :class="{ 'is-invalid': submitted && $v.form.video_id.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.video_id.required" class="invalid-feedback">
                                 Video ID is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="video-chat-id">Vimeo Video Chat ID<span style="color: red">
                                    *</span></label>
                              <b-form-input id="video-chat-id" v-model="form.vchat_id"
                                 placeholder="Enter Vimeo Video Chat ID"
                                 :class="{ 'is-invalid': submitted && $v.form.vchat_id.$invalid }">
                              </b-form-input>
                              <div v-if="submitted && !$v.form.vchat_id.required" class="invalid-feedback">
                                 Vimeo Video Chat ID is
                                 required.</div>
                           </b-form-group>
                           <b-form-group v-slot="{ ariaDescribedby }" class="col-12">
                              <b-form-radio-group id="radio-group-2" v-model="form.video_event_type"
                                 @input="changeEventType" :aria-describedby="ariaDescribedby"
                                 name="radio-sub-component">
                                 <b-form-radio value="0">Is Vimeo Event</b-form-radio>
                                 <b-form-radio value="3">Is AWS Video Event</b-form-radio>
                                 <b-form-radio value="1">Is Youtube Stream</b-form-radio>
                                 <b-form-radio value="2">Other Stream (Eg : Dacast)</b-form-radio>
                              </b-form-radio-group>
                           </b-form-group>
                           <!-- <template v-if="is_video"> -->

                           <!-- </template> -->
                           <template v-if="!is_video">
                              <b-form-group class="col-12" label-for="aws-link"
                                 :label="form.video_event_type == '3' || form.video_event_type == '2' ?
                                 'Link Of Other Video (Eg: dacast (add https://iframe.dacast.com/live/))' : 'Youtube Link Id (Eg: HObzRk0pU9Y)'">
                                 <b-form-input id="aws-link" v-model="form.video_link"
                                    placeholder="Enter Video Link if other than Vimeo"
                                    :class="{ 'is-invalid': submitted && $v.form.video_link.$invalid }">
                                 </b-form-input>
                                 <div v-if="submitted && !$v.form.video_link.required" class="invalid-feedback">
                                    Video Link is
                                    required.</div>
                              </b-form-group>
                           </template>
                           <!-- <template v-if="is_youtube">
                              <b-form-group class="col-12" label="Youtube Link Id (Eg: HObzRk0pU9Y)"
                                 label-for="youtube-link">
                                 <b-form-input id="youtube-link" v-model="form.video_link"
                                    placeholder="Enter Video Link if other than Vimeo">
                                 </b-form-input>
                              </b-form-group>
                           </template>
                           <template v-if="is_other">
                              <b-form-group class="col-12"
                                 label="Link Of Other Video (Eg: dacast (add 'https://iframe.dacast.com/live/'))"
                                 label-for="other-video-link">
                                 <b-form-input id="other-video-link" v-model="form.video_link"
                                    placeholder="Enter Video Link if other than Vimeo">
                                 </b-form-input>
                              </b-form-group>
                           </template> -->
                          <b-form-group class="col-6" label="Registration Background Image" label-for="succ1">
                            <b-form-file id="succ1" accept="image/*"
                                         @change="readFile($event, 'background_image')"
                                         placeholder="Choose a file [Upload Max File Size : 2MB]" ref="background_image">
                            </b-form-file>
                            <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.background_image_url
                              ">
                              <img :src="edit.background_image_url" width="128px" height="128px"
                                   style="object-fit: contain; margin-top: 5px" />
                            </template>
                            <template v-if="background_image_url">
                              <img :src="background_image_url" width="128px" height="128px"
                                   ref="background_image" style="object-fit: contain; margin-top: 5px" />
                            </template>
                          </b-form-group>
                          <b-form-group class="col-6" label="Registration Logo Image" label-for="succ2">
                            <b-form-file id="succ2" accept="image/*"
                                         @change="readFile($event, 'logo_image')"
                                         placeholder="Choose a file [Upload Max File Size : 2MB]" ref="logo_image">
                            </b-form-file>
                            <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.logo_image_url
                              ">
                              <img :src="edit.logo_image_url" width="128px" height="128px"
                                   style="object-fit: contain; margin-top: 5px" />
                            </template>
                            <template v-if="logo_image_url">
                              <img :src="logo_image_url" width="128px" height="128px"
                                   ref="logo_image" style="object-fit: contain; margin-top: 5px" />
                            </template>
                          </b-form-group>
                           <b-form-group class="col-6" label="Success Registration Email Image" label-for="succ">
                              <b-form-file id="succ" accept="image/*"
                                 @change="readFile($event, 'register_success_image')"
                                 placeholder="Choose a file [Upload Max File Size : 2MB]" ref="register_success_image">
                              </b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.register_success_image_url
                              ">
                                 <img :src="edit.register_success_image_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="register_success_image_url">
                                 <img :src="register_success_image_url" width="128px" height="128px"
                                    ref="register_success_image_url" style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-6" label="Event Completion Email Image" label-for="comp">
                              <b-form-file id="comp" accept="image/*"
                                 @change="readFile($event, 'event_completed_image')" ref="event_completed_image"
                                 placeholder="Choose a file [Upload Max File Size : 2MB]"></b-form-file>
                              <template v-if="
                                 $route.name == 'edit-live-event' &&
                                 edit.event_completed_image_url
                              ">
                                 <img :src="edit.event_completed_image_url" width="128px" height="128px"
                                    style="object-fit: contain; margin-top: 5px" />
                              </template>
                              <template v-if="event_completed_image_url">
                                 <img :src="event_completed_image_url" width="128px" height="128px"
                                    ref="event_completed_image_url" style="object-fit: contain; margin-top: 5px" />
                              </template>
                           </b-form-group>
                           <b-form-group class="col-6">
                            <label for="multi_event">Other Events To List</label>
                            <multiselect @search-change="getEventList" id="events" :options="getEvents"
                              v-model="form.multi_events" :multiple="true" track-by="id" label="title"
                              placeholder="Type here to search">
                              <span slot="noOptions">
                                 Type here to search
                              </span>
                            </multiselect>
                          </b-form-group>
                           <b-form-group class="col-6">
                              <label for="partner">Partner <span style="color: red">*</span></label>
                              <multiselect @search-change="fetchPartner" id="partner" :options="getPartner"
                                 v-model="form.partner_id" :multiple="false" track-by="id" label="title"
                                 :class="{ 'is-invalid': submitted && $v.form.partner_id.$invalid }"
                                 placeholder="Type here to search">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect>
                              <div v-if="submitted && !$v.form.partner_id.required" class="invalid-feedback">Partner is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-6" label="Forum" label-for="forum">
                              <multiselect @search-change="fetchForum" id="forum" :options="getForum"
                                 v-model="form.partner_division_id" :multiple="false" track-by="id" label="title"
                                 placeholder="Type here to search">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <div class="d-flex justify-content-between align-items-center mb-2">
                              <label for="comm" class="mb-0">Community</label>
                              <b-form-checkbox v-model="status" button button-variant="info" size="sm">
                                 <template v-if="status">Unselect All</template>
                                 <template v-else>Select All</template>
                              </b-form-checkbox>
                              </div>
                              <multiselect @search-change="fetchCommunity" id="comm" v-model="form.community_selected"
                                :options="getCommunity" :multiple="true" track-by="id" label="title"
                                 placeholder="Type here to search">
                                 <span slot="noOptions"> Type here to search </span>
                              </multiselect>
                           </b-form-group>
                           <b-form-group class="col-6" label="Knowledge Category" label-for="know-cat">
                              <!-- <multiselect id="know-cat"
                                 :options="getKnowledgeCategory" v-model="form.knowledge_category_id" :multiple="false"
                                 track-by="id" label="title" placeholder="Type here to search">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect> -->
                              <b-form-select id="know-cat" v-model="form.knowledge_category_id" value-field="id"
                                 text-field="title" :options="getKnowledgeCategory">
                              </b-form-select>
                           </b-form-group>
                           <b-form-group class="col-6" label="Expert" label-for="expert">
                              <multiselect @search-change="fetchExpert" id="expert" :options="getExpert"
                                 v-model="form.experts" :multiple="true" track-by="id" label="name"
                                 placeholder="Type here to search">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect>
                           </b-form-group>
                           <b-form-group class="col-6" label="Sub Speciality" label-for="sub-spec">
                              <multiselect id="sub-spec" :options="getSubSpeciality" v-model="form.sub_specialities"
                                 :multiple="true" track-by="id" label="name" placeholder="Type here to search">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="user-type">Live event country <span style="color: red">*</span></label>
                              <multiselect id="country" :options="allCountryWithAll" v-model="form.live_event_country"
                                 :multiple="false" track-by="id" label="name" placeholder="Type here to search"
                                 :class="{ 'is-invalid': submitted && $v.form.live_event_country.$invalid }">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect>
                              <div v-if="submitted && !$v.form.live_event_country.required" class="invalid-feedback">Live event country is required.</div>
                           </b-form-group>
                           <b-form-group class="col-6" label="Quiz" label-for="quiz">
                              <b-form-select id="quiz" v-model="form.quiz_id" value-field="id" text-field="title"
                                 :options="getQuiz">
                              </b-form-select>
                           </b-form-group>
                           <b-form-group label="Member Tagging" label-for="member" class="col-6">
                              <multiselect @search-change="fetchMemberList" id="member" v-model="form.member_tagging"
                                 :options="memberList" :multiple="true" track-by="member_id" label="fnameAndLname"
                                 placeholder="Type here to search">
                                 <span slot="noOptions">
                                    Type here to search
                                 </span>
                              </multiselect>
                           </b-form-group>
                           <b-form-group class="col-6" label="Thankyou Content" label-for="tyc">
                              <b-form-select id="tyc" v-model="form.send_msg_content_thankyou" value-field="value" text-field="text"
                                 :options="getContentType">
                              </b-form-select>
                           </b-form-group>
                           <template v-if="form.send_msg_content_thankyou == 'message'">
                              <b-form-group label="Message" label-for="tym" class="col-6">
                                <b-form-input id="tym" v-model="form.message" placeholder="Type Message Here" :class="{
                                  'is-invalid': submitted && $v.form.message.$invalid,
                                }"></b-form-input>
                                 <div v-if="submitted && !$v.form.message.required" class="invalid-feedback">
                                  Message is required when Thankyou Content is Message.
                                </div>
                              </b-form-group>
                           </template>
                           <b-form-group class="col-12">
                              <div style="display: flex; gap: 10px; padding: 2px 0">
                                 <b-form-checkbox v-model="form.is_payed_event" @input="changePayed">Is Payed Event
                                 </b-form-checkbox>
                                 <b-form-checkbox v-model="form.non_medical_event">Is Non Medical Event
                                 </b-form-checkbox>
                                 <b-form-checkbox v-model="form.show_in_app">Show in App</b-form-checkbox>
                                 <b-form-checkbox v-model="form.is_open_event">Is Open Event</b-form-checkbox>
                                 <b-form-checkbox v-model="form.send_sms">Send SMS</b-form-checkbox>
                                 <b-form-checkbox v-model="form.has_certificates">Has Certificate</b-form-checkbox>
                                 <b-form-checkbox v-model="form.subsequent_survey">Show Post Webinar Survey
                                 </b-form-checkbox>
                                 <b-form-checkbox v-model="form.send_email">Send Email</b-form-checkbox>
                              </div>
                              <div style="display: flex; gap: 10px; padding: 2px 0px;">
                                 <b-form-checkbox v-model="form.is_user_type" @input="changeUser">Is User Type
                                 </b-form-checkbox>
                                 <b-form-checkbox v-model="form.show_in_home">Show in Home Page</b-form-checkbox>
                                 <b-form-checkbox v-model="form.is_survey_event">Is Survey Event</b-form-checkbox>
                                 <b-form-checkbox v-model="form.hide_video">Hide Video</b-form-checkbox>
                                 <b-form-checkbox v-model="form.send_whatsapp">Send Whatsapp Message</b-form-checkbox>
                                 <b-form-checkbox v-model="form.show_hospital_name">Show Hospital Name</b-form-checkbox>
                                 <b-form-checkbox v-model="form.is_speciality" @input="changeSpec">Is Speciality</b-form-checkbox>
                              </div>
                              <div style="display: flex; gap: 10px; padding: 2px 0px;">
                                 <b-form-checkbox v-model="form.survey_align">Show Survey Right Side</b-form-checkbox>
                                 <b-form-checkbox v-model="form.is_otp_type">Is OTP</b-form-checkbox>
                                 <b-form-checkbox v-model="form.show_header_footer">Show Header and Footer</b-form-checkbox>
                                 <b-form-checkbox v-model="form.is_mmc_number">Is MMC Number</b-form-checkbox>
                                 <b-form-checkbox v-model="form.is_protected" @change="handleCheckboxChange">Is Protected</b-form-checkbox>
                              </div>
                           </b-form-group>
                           <template v-if="isUserType">
                              <b-form-group class="col-12">
                                 <label for="user-type">User Type <span style="color: red">*</span></label>
                                 <multiselect id="user-type" v-model="form.user_types" :options="getUserType"
                                    :multiple="true" track-by="value" label="name"
                                    :class="{ 'is-invalid': submitted && $v.form.user_types.$invalid }">
                                    <span slot="noOptions">
                                       Type here to search
                                    </span>
                                 </multiselect>
                                 <div v-if="submitted && !$v.form.user_types.required" class="invalid-feedback">User
                                    Type is
                                    required when Is User Type is checked.</div>
                              </b-form-group>
                           </template>
                           <template v-if="isSpec">
                              <b-form-group class="col-12">
                                 <label for="user-type">Speciality</label>
                                 <multiselect id="user-type" v-model="form.speciality_to_show" :options="getSpec"
                                    :multiple="true" track-by="id" label="title">
                                    <span slot="noOptions">
                                       Type here to search
                                    </span>
                                 </multiselect>
                              </b-form-group>
                           </template>
                        </div>
                     </tab-content>
                     <tab-content title="Advanced" icon="fa fa-tag">
                        <div class="row">
                           <b-form-group class="col-6 position-relative" id="input-group-5" label="Meta Title"
                           label-for="input-5">
                           <b-form-input id="input-5" :maxlength="190" v-model="form.meta_title"
                              placeholder="Enter Meta Title"></b-form-input>
                           <div class="text-right">
                              <p v-if="form.meta_title" class="badge position-absolute" style="top: 4px; right: 13px" :class="{
                                 'badge-success': form.meta_title.length !== 190,
                                 'badge-danger': form.meta_title.length === 190
                              }">
                                 You typed
                                 {{ form.meta_title.length }} out of 190 chars.
                              </p>
                           </div>
                           </b-form-group>
                           <b-form-group class="col-6 position-relative" label="Meta Keywords" label-for="input-7">
                           <b-form-input id="input-7" :maxlength="250" v-model="form.meta_keywords" placeholder="Enter Meta Keywords">
                           </b-form-input>
                           <div class="text-right">
                              <p v-if="form.meta_keywords" class="badge position-absolute" style="top: 4px; right: 13px"
                                 :class="{
                                 'badge-success': form.meta_keywords.length !== 250,
                                 'badge-danger': form.meta_keywords.length === 250
                                 }">
                                 You typed
                                 {{ form.meta_keywords.length }} out of 250 chars.
                              </p>
                           </div>
                           </b-form-group>
                        </div>
                        <b-form-group id="input-group-6" label="Meta Description" label-for="input-6"
                           class="mb-4 position-relative">
                           <b-form-textarea id="input-6" v-model="form.meta_description" placeholder="Enter Meta Description"
                           rows="3" :maxlength="400"></b-form-textarea>
                           <div class="text-right">
                           <p v-if="form.meta_description" class="badge position-absolute" style="top: 4px; right: 13px"
                              :class="{
                                 'badge-success': form.meta_description.length !== 400,
                                 'badge-danger': form.meta_description.length === 400
                              }">
                              You typed
                              {{ form.meta_description.length }} out of 400 chars.
                           </p>
                           </div>
                        </b-form-group>
                        <div class="d-flex align-items-center mb-1">
                           <i class="mdi mdi-file-plus-outline mr-2"></i>
                           <h6 class="mb-0">Registration Tabs</h6>
                        </div>
                        <div class="row" v-for="(reg, i) in form.registration_tabs" :key="i">
                           <b-form-group class="col-5" label="Label" label-for="adv-1">
                              <b-form-input id="adv-1" v-model="reg.label" :placeholder="reg.label"></b-form-input>
                           </b-form-group>
                           <b-form-group class="col-5" label="Placeholder" label-for="adv-1">
                              <b-form-input id="adv-1" v-model="reg.placeholder" :placeholder="reg.placeholder">
                              </b-form-input>
                           </b-form-group>
                           <b-form-group class="col-2 ac-req-check">
                              <b-form-checkbox value="on" unchecked-value="off" v-model="reg.required">
                                 Required</b-form-checkbox>
                              <b-form-checkbox value="on" unchecked-value="off" v-model="reg.active" id="">
                                 Active</b-form-checkbox>
                           </b-form-group>
                        </div>
                     </tab-content>
                     <tab-content title="Feedback Survey" icon="ri-feedback-fill"
                        :before-change="() => validateFormThree()">
                        <div class="d-flex align-items-center mb-1">
                           <i class="mdi mdi-file-plus-outline mr-2"></i>
                           <h6 class="mb-0">Feedback Survey</h6>
                        </div>
                        <b-form-group label="Feedback Survey Name" label-for="fee-sur">
                           <b-form-select :options="surveyName" value-field="value" text-field="text"
                              v-model="form.survey_type" @input="changeSurvey" id="fee-sur">
                           </b-form-select>
                        </b-form-group>
                        <div v-for="(survey, index) in form.feedback_survey" :key="index">
                           <div class="row" v-if="isPrePost">
                              <b-form-group class="col-4" label="Question" label-for="ques">
                                 <b-form-input placeholder="Enter Question" v-model="survey.question" id="ques"
                                    :class="{ 'is-invalid': submitted2 && $v.form.feedback_survey.$each[index].question.$invalid }">
                                 </b-form-input>
                                 <div v-if="submitted2 && !$v.form.feedback_survey.$each[index].question.required"
                                    class="invalid-feedback">Question is required.</div>
                              </b-form-group>
                              <b-form-group class="col-4">
                                 <label for="rat-type">Rating Type <span style="color: red">*</span></label>
                                 <b-form-select :options="ratingType" v-model="survey.feedback_type" value-field="value"
                                    text-field="text"
                                    :class="{ 'is-invalid': submitted2 && $v.form.feedback_survey.$each[index].feedback_type.$invalid }">
                                 </b-form-select>
                                 <div v-if="submitted2 && !$v.form.feedback_survey.$each[index].feedback_type.required"
                                    class="invalid-feedback">Rating Type is required.</div>
                              </b-form-group>
                              <b-form-checkbox v-model="survey.is_active" value="1" unchecked-value="0"
                                 class="col-2 is-active">Is Active
                              </b-form-checkbox>
                              <div class="col-2 add-del-ques">
                                 <b-button variant="success" class="mr-3 btn-sm" @click.prevent="addFeedback">+
                                 </b-button>
                                 <b-button variant="danger" class="btn-sm" @click.prevent="removeFeedback(index)">-
                                 </b-button>
                              </div>
                           </div>
                           <template v-if="survey.feedback_type == 'option_rating'">
                              <div class="row" v-for="(val, i) in survey.option" :key="i">
                                 <b-form-group class="col-8">
                                    <label label-for="opt">Add Option <span style="color: red">*</span></label>
                                    <b-form-input id="opt" @keydown.space="preventLeadingSpace" v-model="val.value"
                                       placeholder="Enter Option">
                                    </b-form-input>
                                 </b-form-group>
                                 <div class="col-4" style="margin-top: 30px; text-align: start">
                                    <b-button variant="success" :disabled="val.value[i] == null"
                                       @click.prevent="addOption(index)" class="mr-3">Add
                                       Option</b-button>
                                    <b-button @click.prevent="removeOption(index, i)" variant="danger">Remove
                                       Option
                                    </b-button>
                                 </div>
                              </div>
                           </template>
                        </div>
                     </tab-content>
                     <tab-content v-if="isPayed" title="Payment Settings" icon="ri-secure-payment-fill"
                        :before-change="() => validateFormFour()">
                        <div class="d-flex align-items-center mb-1">
                           <i class="mdi mdi-file-plus-outline mr-2"></i>
                           <h6 class="mb-0">Payment Settings</h6>
                        </div>
                        <div class="row">
                           <b-form-group class="col-6">
                              <label for="am">Amount<span style="color: red"> *</span></label>
                              <b-form-input type="number" min="0" v-model="form.amount" id="am" placeholder="Enter Amount"
                                 :class="{ 'is-invalid': submitted3 && $v.form.amount.$invalid }">
                              </b-form-input>
                              <div v-if="submitted3 && !$v.form.amount.required" class="invalid-feedback">Amount is
                                 required.</div>
                           </b-form-group>
                           <b-form-group class="col-6">
                              <label for="curr">Currency<span style="color: red"> *</span></label>
                              <b-form-select :options="currencyOpt" v-model="form.currency" value-field="value"
                                 text-field="text" id="curr"
                                 :class="{ 'is-invalid': submitted3 && $v.form.currency.$invalid }">
                              </b-form-select>
                              <div v-if="submitted3 && !$v.form.currency.required" class="invalid-feedback">Currency is
                                 required.</div>
                           </b-form-group>
                        </div>
                     </tab-content>
                  </form-wizard>
               </div>
            </div>
         </div>
      </div>
   </Layout>
</template>
 
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import liveEvent from "../../../mixins/ModuleJs/live-event";
import Multiselect from "vue-multiselect";
import { FormWizard, TabContent } from "vue-form-wizard";
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
   components: {
      Layout,
      PageHeader,
      Multiselect,
      FormWizard,
      TabContent
   },
   mixins: [MixinRequest, liveEvent],
   data() {
      return {
         form: {
            is_protected: false,
         },
         timeZone: { title: 'Time Zone for Indonesia : Asia/Jakarta' },
         timeZoneCode: { title: 'Time Zone Code for Indonesia : WIB' },
         submitted: false,
         submitted2: false,
         submitted3: false,
         title1: "Add Live Event",
         title2: "Edit Live Event",
         items: [
            {
               text: "Back",
               href: "/live-event",
            },
            {
               text: "Data",
            },
         ],
      };
   },
   methods: {
      // Alert for is_protected
      async handleCheckboxChange() {
         try {
            let result = await this.showSweetAlert();
            if (result.isConfirmed) {
               // When user cick OK
               this.form.is_protected = true;
            } else {
               // When user click CANCEL
               this.form.is_protected = false;
            }
         } catch (error) {
            console.error('Error:', error);
         }
      },
      showSweetAlert() {
         return new Promise((resolve) => {
            this.$swal({
               title: 'Are you sure?',
               text: 'You are about to mark this event as a protected.',
               icon: 'warning',
               showCancelButton: true,
               confirmButtonColor: '#3085d6',
               cancelButtonColor: '#d33',
               confirmButtonText: 'OK',
               cancelButtonText: 'Cancel',
               dangerMode: true,
            }).then((result) => {
               resolve(result);
            });
         });
      },
      disabledDates() {
         if (this.form.session_time) {
            const startDate = new Date(this.form.session_time);
            startDate.setMinutes(startDate.getMinutes() + 1); // Add 1 minute to avoid the same datetime
            return startDate.toISOString().slice(0, 16);
         }
         return new Date().toISOString().slice(0, 16);
      },
      disabledPastDates() {
         const currentDate = new Date();
         currentDate.setTime(currentDate.getTime() + 60000); // Add 1 minute to the current date
         return currentDate.toISOString().slice(0, 16);
      },
      preventLeadingSpace(e) {
         if (!e.target.value) e.preventDefault();
         else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
      },
      validateFormOne() {
         this.submitted = true;
         if (this.$v.form.title.$invalid || this.$v.form.buffer_time.$invalid || this.$v.form.session_end_time.$invalid || this.$v.form.session_time.$invalid || this.$v.form.live_event_text.$invalid || this.$v.form.partner_id.$invalid || this.$v.form.link_id.$invalid || this.$v.form.video_id.$invalid || this.$v.form.vchat_id.$invalid || this.$v.form.user_types.$invalid || this.$v.form.message.$invalid || this.$v.form.live_event_country.$invalid) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Please Fill The Required Fields"
            });
            return false;
         }
         if (this.form.buffer_time <= 0) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Buffer Time should be greater than 0"
            });
            return false;
         }
         return true;
      },
      validateFormThree() {
         this.submitted2 = true;
         let status = true;
         let correct_option = 0;
         if (this.form.survey_type == 'pre' || this.form.survey_type == 'post') {
            for (let index = 0; index < this.form.feedback_survey.length; index++) {
               if (this.form.feedback_survey[index].option != null) {
                  for (let i = 0; i < this.form.feedback_survey[index].option.length; i++) {
                     if (this.form.feedback_survey[index].feedback_type == 'option_rating') {
                        if (this.form.feedback_survey[index].option[i].value == '') {
                           correct_option++;
                        }
                     }
                  }
               }

               if (this.$v.form.feedback_survey.$each[index].question.$invalid === true) { status = false; }
               if (this.$v.form.feedback_survey.$each[index].feedback_type.$invalid === true) { status = false; }
               if (correct_option > 0) {
                  this.$store.commit("toast/updateStatus", {
                     status: true,
                     icon: "error",
                     title: "Please fill Add Option field"
                  });
                  return false;
               }
            }
         }
         return status;
      },
      validateFormFour() {
         this.submitted3 = true;
         if (this.$v.form.amount.$invalid || this.$v.form.currency.$invalid) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Please Fill The Required Fields"
            });
            return false;
         }
         if (this.form.amount <= 0) {
            this.$store.commit("toast/updateStatus", {
               status: true,
               icon: "error",
               title: "Amount should be greater than 0"
            });
            return false;
         }
         return true;
      }
   },
   validations: {
      form: {
         title: { required },
         link_id: { required },
         session_time: { required },
         session_end_time: { required },
         live_event_text: { required },
         partner_id: { required },
         video_id: { required },
         vchat_id: { required },
         video_link: { required: requiredIf(form => form.video_event_type == 3 || form.video_event_type == 2 || form.$eachvideo_event_type == 1) },
         buffer_time: { required },
         amount: { required: requiredIf(form => form.is_payed_event == 1) },
         currency: { required: requiredIf(form => form.is_payed_event == 1) },
         user_types: { required: requiredIf(form => form.is_user_type == 1) },
         live_event_country: { required },
         feedback_survey: {
            $each: {
               question: { required },
               feedback_type: { required },
               option: { required }
            }
         },
        message: { required: requiredIf((form) => form.send_msg_content_thankyou == 'message') }
      },
   }
};
</script>
 