import _ from "lodash";
import {
  community,
  forum,
  live_event,
  partner,
  knowledgeCategory,
  expert,
  quiz,
  speciality,
  subspeciality,
  userType,
  country,
  members
} from "../../js/path";

export default {
  data() {
     return {
        status: false,
        pageNo: null,
        liveEventId: null,
        isPayed: false,
        fields: [{
              key: "id",
              label: "ID",
              sortable: true,
           },
           {
              key: "title",
              class: "w-200"
           },
           {
              key: "link_id",
              label: "Link ID",
           },
           {
              key: "Status"
           },
           {
              key: 'active_chat',
              label: 'Chat Status'
           },
           {
              key: 'private_chat',
              label: 'Private Chat'
           },
           {
              label: 'Moderator Link',
              key: 'moderator_link'
           },
           {
              label: 'Generate Certificate',
              key: 'has_certificates'
           },
           {
              key: 'download'
           },
           {
              key: 'sendEmail'
           },
           {
              key: 'edit'
           },
           {
              key: 'delete'
           },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        currentPage: 1,
        activeTab: "all",
        form: {
           view_multiplication_factor: 0,
           message: "",
           send_msg_content_thankyou: null,
           multi_events: [],
           registration_tabs: {
              city: {
                 label: "City",
                 active: "on",
                 required: "on",
                 placeholder: "City"
              },
              email: {
                 label: "Email",
                 active: "on",
                 required: "on",
                 placeholder: "Email"
              },
              state: {
                 label: "State",
                 active: "on",
                 required: "on",
                 placeholder: "State"
              },
              company: {
                 label: "Company name",
                 placeholder: "Company name",
                 active: "off",
                 required: "off",
              },
              community: {
                 label: "Community",
                 placeholder: "Community",
                 active: "off",
                 required: "off",
              },
              last_name: {
                 label: "Last name",
                 active: "on",
                 required: "on",
                 placeholder: "Last name"
              },
              first_name: {
                 label: "First name",
                 active: "on",
                 required: "on",
                 placeholder: "First name"
              },
              hospital_name: {
                 label: "Hospital name",
                 placeholder: "Hospital name",
                 active: "off",
                 required: "off",
              },
              mobile_number: {
                 label: "Phone number",
                 active: "on",
                 required: "on",
                 placeholder: "Phone number"
              },
              registration_id: {
                 label: "Registration id",
                 placeholder: "Registration id",
                 active: "off",
                 required: "off",
              },
              registration_number: {
                 label: "Registration number",
                 active: "off",
                 required: "off",
                 placeholder: "Registration number"
              },
              medical_registered_state: {
                 label: "Medical Registered State",
                 active: "off",
                 required: "off",
                 placeholder: "Medical Registered State"
              } 
           },
           title: '',
           link_id: '',
           description: '',
           session_time: '',
           session_end_time: '',
           time_zone: '',
           time_zone_code: '',
           buffer_time: '',
           live_event_text: '',
           email_link: '',
           external_event: '',
           redirect_away_without_registration: '',
           chat_type: '',
           tnc_detail: '',
           calender_description: '',
           video_id: '',
           vchat_id: '',
           video_link: '',
           partner_id: '',
           partner_division_id: '',
           community_selected: '',
           knowledge_category_id: '',
           quiz_id: 0,
           experts: [],
           video_event_type: "0",
           sub_specialities: [],
           is_payed_event: 0,
           is_protected: 0,
           non_medical_event: 0,
           is_survey_event: 0,
           show_in_app: 0,
           is_open_event: 0,
           hide_video: 0,
           is_mmc_number: 0,
           is_speciality: 0,
           is_otp_type: 0,
           survey_align: 0,
           send_sms: 0,
           is_user_type: 0,
           show_in_home: 0,
           show_header_footer: 0,
           send_whatsapp: 0,
           send_email: 0,
           subsequent_survey: 0,
           has_certificates: 0,
           show_hospital_name: 0,
           user_types: [],
           speciality_to_show: [],
           survey_type: '',
           feedback_survey: [{
              question: '',
              feedback_type: '',
              is_active: 0,
              id: 0,
              option: [{
                 value: ''
              }],
           }],
           amount: '',
           currency: '',
           content_type: 'live_event',
           moderator_password: '',
           certificate_id: 0,
           rewards: 0.00,
           password: '',
           password_placeholder: '',
           meta_title: "",
           meta_description: "",
           meta_keywords: "",
           live_event_country:"",
           member_tagging: [],
           certificate: [],
           base_s3_url_certificate : "",
           memberCertificates:[]
        },
        currencyOpt: [{
              text: 'USD (US)',
              value: 'USD'
           },
           {
              text: 'INR (India)',
              value: 'INR'
           },
           {
              text: 'IDR (Indonesia)',
              value: 'IDR'
           },
           {
              text: 'MYR (Malaysia)',
              value: 'MYR'
           }
        ],
        surveyName: [{
              value: 'pre',
              text: 'Pre Feedback Survey'
           },
           {
              value: 'post',
              text: 'Post Feedback Survey'
           }
        ],
        ratingType: [{
              value: 'star_rating',
              text: 'Star Rating'
           },
         //   {
         //      value: 'bullet_rating',
         //      text: 'Bullet Rating'
         //   },
           {
              value: 'input_rating',
              text: 'Input Rating'
           },
           {
              value: 'option_rating',
              text: 'Option Rating'
           }
        ],
        chatType: [{
              text: 'Vimeo Chat',
              value: 0
           },
           {
              text: 'Medisage Chat',
              value: 1
           }
        ],
        is_video: true,
        isUserType: false,
        isSpec: false,
        isPrePost: false,
        getPartner: [],
        getEvents: [],
        getForum: [],
        getCommunity: [],
        getKnowledgeCategory: [],
        getExpert: [],
        getQuiz: [],
        getSubSpeciality: [],
        getCountry:[],
        getUserType: [],
        getSpec: [],
        banner_image: '',
        mobile_banner_image: '',
        thumbnail_image: '',
        register_image1: '',
        register_image2: '',
        register_success_image: '',
        background_image : '',
        logo_image : '',
        event_completed_image: '',
        banner_image_url: null,
        mobile_banner_image_url: null,
        thumbnail_image_url: null,
        background_image_url: null,
        logo_image_url: null,
        register_image1_url: null,
        register_image2_url: null,
        register_success_image_url: null,
        event_completed_image_url: null,
        edit: {
           banner_image_url: null,
           mobile_banner_image_url: null,
           thumbnail_image_url: null,
           background_image: null,
           logo_image: null,
           background_image_url: null,
           logo_image_url: null,
           register_image1_url: null,
           register_image2_url: null,
           register_success_image_url: null,
           event_completed_image_url: null
        },
        getContentType: [
           {
              text: 'Default',
              value: 'default'
           },
           {
              text: 'Message',
              value: 'message',
           },
           {
              text: 'No Message',
              value: 'no_message'
           }
        ],
        memberList: [],
        oldForm: {},  
     };
  },
  computed: {
     allCountryWithAll() {
        return [{ id: 'all', phonecode: 'all', name: 'All' }, ...this.getCountry];
     }
   },
  methods: {
      async fetchAllCommunity() {
         const url = community.fetchAllActiveCommunity;
         const data = await this.getRequest(url);
         if (data.status) {
         this.form.community_selected = data.response.data;
         }
      },
   clearEndTime() {
      this.form.session_end_time = '';
   },
    fetchMemberList: _.debounce(async function (query) {
      this.$store.commit("loader/updateStatus", false);
      this.memberList = [];
      if (query != "" && query != null) {
         this.$store.commit("loader/updateStatus", true);
         const url = members.getMembers;
         const data = await this.getRequest(url + '?search=' + query);
         if (data.status) {
            this.memberList = data.response.data.map(member => ({
               member_id: member.id,
               fnameAndLname: `${member.fname} ${member.lname} - ${member.mobile_number}`,
            }));
        }
      }
      this.$store.commit("loader/updateStatus", false);
   }, 1000),
   async fetchSingleMember(id) {
    const url = members.getMembers;
    const data = await this.getRequest(url + "/" + id);
    if (data.status) {
      const myObj = {
        member_id: data.response.id,
        fnameAndLname: `${data.response.fname} ${data.response.lname} - ${data.response.mobile_number}`
      }
      this.form.member_tagging.push(myObj);
      }
    },
     async fetchPartner(query) {
        const url = partner.partnerUrl;
        const data = await this.getRequest(url + '?search=' + query);
        if (data.status) {
           this.getPartner = data.response.data;
        }
     },
     async getEventList(query){
        const url = live_event.fetchAllLive_event;
        if(query.length > 3 || query.length == 0){
           const data = await this.getRequest(url + '?no_pagination&search=' + query);
           if (data.status) {
              this.getEvents = data.response.data.length > 0 ? data.response.data.map(d => {
                 let res = {};
                 res.id = d.id;
                 res.title = d.title;
                 return res
              }) : [];
           }
        }
     },
     async fetchSinglePartner(id) {
        const url = partner.partnerUrl;
        const data = await this.getRequest(url + '/' + id);
        if (data.status) {
           this.form.partner_id = data.response;
        }
     },
     async fetchForum(query) {
        const url = forum.fetchAllActiveForum;
        const data = await this.getRequest(url + '&search=' + query);
        if (data.status) {
           this.getForum = data.response.data;
        }
     },
     async fetchSingleForum(id) {
        const url = forum.forumUrl;
        const data = await this.getRequest(url + '/' + id);
        if (data.status) {
           this.form.partner_division_id = data.response;
        }
     },
     async fetchCommunity(query) {
        const url = community.fetchAllActiveCommunity;
        const data = await this.getRequest(url + '&search=' + query);
        if (data.status) {
           this.getCommunity = data.response.data;
        }
     },
     async fetchSingleCommunity(id) {
        const url = community.fetchCommunity;
        const data = await this.getRequest(url + '/' + id);
        if (data.status) {
           this.form.community_id = data.response;
        }
     },
     async fetchKnowledgeCategory() {
        const url = knowledgeCategory.fetchKnowledgeCategory;
        const data = await this.getRequest(url);
        if (data.status) {
           this.getKnowledgeCategory = data.response;
        }
     },
     async fetchExpert(query) {
        const url = expert.expertUrl;
        const data = await this.getRequest(url + '?search=' + query);
        if (data.status) {
           this.getExpert = data.response.data;
        }
     },
     async fetchSingleExpert(id) {
        const url = expert.expertUrl;
        const data = await this.getRequest(url + '/' + id);
        if (data.status) {
           this.form.experts.push(data.response);
        }
     },
     async fetchQuiz() {
        const url = quiz.fetchQuiz;
        const data = await this.getRequest(url);
        if (data.status) {
           this.getQuiz = data.response;
        }
     },
     async fetchSubSpeciality() {
        const url = subspeciality.fetchAllSubSpeciality;
        const data = await this.getRequest(url);
        if (data.status) {
           this.getSubSpeciality = data.response.data;
        }
     },
     async fetchCountry() {
        const url = country.fetchAllCountry;
        const data = await this.getRequest(url);
        if (data.status) {
           this.getCountry = data.response.data;
        }
     },
     async fetchUserType() {
        const url = userType.fetchUser;
        const data = await this.getRequest(url);
        if (data.status) {
           this.getUserType = data.response;
        }
     },
     async fetchSpec(){
        const url = speciality.fetchAllSpeciality;
        const data = await this.getRequest(url);
        if (data.status) {
           this.getSpec = data.response.data;
        }
     },
     changeSurvey(val) {
        if (val == 'pre' || val == 'post') {
           this.isPrePost = true;
        } else {
           this.isPrePost = false;
        }
     },
     changeUser(val) {
        if (val) {
           this.isUserType = true;
        } else {
           this.isUserType = false;
        }
     },
     changeSpec(val){
        if (val) {
           this.isSpec = true;
        }
        else {
           this.form.speciality_to_show = [];
           this.isSpec = false;
        }
     },
     changeEventType(val) {
        if (val == 0) {
           this.is_video = true,
              this.form.video_event_type = val;
        } else {
           this.is_video = false,
              this.form.video_event_type = val;
           // this.form.is_non_vimeo_event = null;
        }
     },
     changePayed(val) {
        if (val) {
           this.isPayed = true;
        } else {
           this.isPayed = false;
        }
     },
     addFeedback() {
        this.form.feedback_survey.push({
           question: "",
           feedback_type: "",
           is_active: 1,
           id: 0,
           option: [{
              value: ''
           }]
        })
     },
     removeFeedback(key) {
        if (key == 0)
           return;
        this.form.feedback_survey.splice(key, 1);
     },
     addOption(key) {
        this.form.feedback_survey[key].option.push({
           value: '',
        });
     },
     removeOption(i, key) {
        if (key == 0) return;
        this.form.feedback_survey[i].option.splice(key, 1);
     },
     readFile(e, txt) {
        if (txt == 'banner_image') {
           this.banner_image = e.target.files[0];
           if (this.banner_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.banner_image.reset();
              this.banner_image_url = '';
              this.edit.banner_image_url = '';
              this.banner_image = '';
              return false
           } else {
              this.banner_image_url = URL.createObjectURL(this.banner_image);
              this.edit.banner_image_url = '';
           }
        } else if (txt == 'mobile_banner_image') {
           this.mobile_banner_image = e.target.files[0];
           if (this.mobile_banner_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.mobile_banner_image.reset();
              this.mobile_banner_image_url = '';
              this.edit.mobile_banner_image_url = '';
              this.mobile_banner_image = '';
              return false
           } else {
              this.mobile_banner_image_url = URL.createObjectURL(this.mobile_banner_image);
              this.edit.mobile_banner_image_url = '';
           }
        } else if (txt == 'thumbnail_image') {
           this.thumbnail_image = e.target.files[0];
           if (this.thumbnail_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.thumbnail_image.reset();
              this.thumbnail_image_url = '';
              this.edit.thumbnail_image_url = '';
              this.thumbnail_image = '';
              return false
           } else {
              this.thumbnail_image_url = URL.createObjectURL(this.thumbnail_image);
              this.edit.thumbnail_image_url = '';
           }
        } else if (txt == 'register_image1') {
           this.register_image1 = e.target.files[0];
           if (this.register_image1.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.register_image1.reset();
              // this.$refs.register_image1_url.reset();
              this.register_image1_url = '';
              this.edit.register_image1_url = '';
              this.register_image1 = '';
              return false
           } else {
              this.register_image1_url = URL.createObjectURL(this.register_image1);
              this.edit.register_image1_url = '';
           }
        } else if (txt == 'register_image2') {
           this.register_image2 = e.target.files[0];
           if (this.register_image2.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.register_image2.reset();
              this.register_image2_url = '';
              this.edit.register_image2_url = '';
              this.register_image2 = '';
              return false
           } else {
              this.register_image2_url = URL.createObjectURL(this.register_image2);
              this.edit.register_image2_url = '';
           }
        } else if (txt == 'register_success_image') {
           this.register_success_image = e.target.files[0];
           if (this.register_success_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.register_success_image.reset();
              this.register_success_image_url = '';
              this.edit.register_success_image_url = '';
              this.register_success_image = '';
              return false
           } else {
              this.register_success_image_url = URL.createObjectURL(this.register_success_image);
              this.edit.register_success_image_url = '';
           }
        }else if (txt == 'background_image') {
           this.background_image = e.target.files[0];
           if (this.background_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.background_image.reset();
              this.background_image = '';
              this.edit.background_image_url = '';
              this.background_image = '';
              return false
           } else {
              this.background_image_url = URL.createObjectURL(this.background_image);
              this.edit.background_image_url = '';
           }
        }else if (txt == 'logo_image') {
           this.logo_image = e.target.files[0];
           if (this.logo_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.logo_image.reset();
              this.logo_image = '';
              this.edit.logo_image_url = '';
              this.logo_image = '';
              return false
           } else {
              this.logo_image_url = URL.createObjectURL(this.logo_image);
              this.edit.logo_image_url = '';
           }
        } else if (txt == 'event_completed_image') {
           this.event_completed_image = e.target.files[0];
           if (this.event_completed_image.size > 2 * 1000 * 1000) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'error',
                 title: 'Uploaded File is more than 2MB'
              });
              this.$refs.event_completed_image.reset();
              this.event_completed_image_url = '';
              this.edit.event_completed_image_url = '';
              this.event_completed_image = '';
              return false
           } else {
              this.event_completed_image_url = URL.createObjectURL(this.event_completed_image);
              this.edit.event_completed_image_url = '';
           }
        }
     },
     search(event) {
        if (this.filter.length > 1) {
           if (event.keyCode == 13) {
               this.currentPage = 1;
              this.fetchData(this.activeTab);
           }
        } else if (this.filter.length == 0) {
         this.currentPage = 1;
         this.fetchData(this.activeTab);
        } 
     },
     searchFor() {
        if (this.filter.length > 0) {
            this.currentPage = 1;
           this.fetchData(this.activeTab);
        } else {
            this.currentPage = 1;
           this.fetchData(this.activeTab);
        }
     },
     filterPage() {
        if (this.pageNo.length > 0) {
           this.params = `&page=${this.pageNo}`;
           this.fetchData(this.activeTab);
           this.currentPage = this.pageNo;
        }
     },
     onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
     },
     async fetchData(txt) {
        this.$store.commit("loader/updateStatus", true);
         let url = null;
         if (txt == "active") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
            url =  live_event.liveEventUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
            url =  live_event.liveEventUrl + "?filter=" + txt;
            }
         } else if (txt == "trash") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
            url =  live_event.liveEventUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
            url =  live_event.liveEventUrl + "?filter=" + txt;
            }
         } else if (txt == "all") {
            this.activeTab = txt;
            if (this.filter != null && this.filter != "") {
            url =  live_event.liveEventUrl + "?filter=" + txt + "&search=" + this.filter;
            } else {
            url =  live_event.liveEventUrl + "?filter=" + txt;
            }
         }
         url += this.params;
         this.getUrl();
         this.$store.commit("loader/updateStatus", true);
         try {
            const data = await this.getRequest(url);
            if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
            }
         } catch (err) {
            this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
            });
         }
        this.$store.commit("loader/updateStatus", false);
     },
      getUrl() {
         var url = new URL(window.location);
         if (this.activeTab == null || this.activeTab == "") {
         this.activeTab = "all";
         }
         url.searchParams.has("filter")
         ? url.searchParams.set("filter", this.activeTab)
         : url.searchParams.append("filter", this.activeTab);
         if (this.filter != null && this.filter != "") {
         url.searchParams.has("search")
            ? url.searchParams.set("search", this.filter)
            : url.searchParams.append("search", this.filter);
         } else {
         url.searchParams.delete("search");
         }
         if (this.currentPage != null && this.currentPage != "") {
         url.searchParams.has("page")
            ? url.searchParams.set("page", this.currentPage)
            : url.searchParams.append("page", this.currentPage);
         } else {
         url.searchParams.set("page", 1);
         }
         url.search = url.searchParams;
         url = url.toString();
         history.pushState({}, null, url);
      },
     async updateStatus(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
           let index = this.tableData.data.findIndex((e) => e.id === id);
           const url = live_event.updateLiveEventStatus;
           const data = await this.postRequest(url, {
              is_active: !this.tableData.data[index].is_active,
              id: id,
           });
           if (data.status) {
              this.tableData.data[index].is_active = !this.tableData.data[index].is_active;
              this.$store.commit("toast/updateStatus", {
                 status: true,
                 icon: "success",
                 title: data.message,
              });
           }
           this.key += 1;
        } catch (err) {
           this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
           });
        }
        this.$store.commit("loader/updateStatus", false);
     },
     async updateChatStatus(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
           let index = this.tableData.data.findIndex((e) => e.id === id);
           const url = live_event.updateLiveEventStatus;
           const data = await this.postRequest(url, {
              active_chat: !this.tableData.data[index].active_chat,
              id: id,
           });
           if (data.status) {
              this.tableData.data[index].active_chat = !this.tableData.data[index].active_chat;
              this.$store.commit("toast/updateStatus", {
                 status: true,
                 icon: "success",
                 title: data.message,
              });
           }
           this.key += 1;
        } catch (err) {
           this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
           });
        }
        this.$store.commit("loader/updateStatus", false);
     },
     async updatePrivateChat(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
           let index = this.tableData.data.findIndex((e) => e.id === id);
           const url = live_event.updateLiveEventStatus;
           const data = await this.postRequest(url, {
              private_chat: !this.tableData.data[index].private_chat,
              id: id,
           });
           if (data.status) {
              this.tableData.data[index].private_chat = !this.tableData.data[index].private_chat;
              this.$store.commit("toast/updateStatus", {
                 status: true,
                 icon: "success",
                 title: data.message,
              });
           }
           this.key += 1;
        } catch (err) {
           this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
           });
        }
        this.$store.commit("loader/updateStatus", false);
     },
     async deleteLiveEvent(id) {
        this.$store.commit("loader/updateStatus", true);
        try {
           let index = this.tableData.data.findIndex((e) => e.id === id);
           const url = live_event.liveEventUrl + "/" + id;
           const data = await this.postRequest(url, {
              _method: "DELETE",
           });
           if (data.status) {
              this.tableData.data.splice(index, 1);
              this.$store.commit("toast/updateStatus", {
                 status: true,
                 icon: "success",
                 title: data.message,
              });
           }
        } catch (err) {
           this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: err.data ? err.data.message : "Please try again!",
           });
        }
        this.$store.commit("loader/updateStatus", false);
     },
     async fetchLiveEvent(id) {
        this.$store.commit('loader/updateStatus', true);
        try {
           let url = live_event.liveEventUrl + '/' + id;
           const data = await this.getRequest(url);
           if (data.status) {
              const responseData = data.response;
              if (responseData.title != null && responseData.title != "") {
                 this.form.title = responseData.title;
              }
              if (responseData.link_id != null && responseData.link_id != "") {
                 this.form.link_id = responseData.link_id;
              }
              if (responseData.description != null && responseData.description != "") {
                 this.form.description = responseData.description;
              }
              if (responseData.session_time != null && responseData.session_time != "") {
                 this.form.session_time = responseData.session_time;
              }
              if (responseData.session_end_time != null && responseData.session_end_time != "") {
                 this.form.session_end_time = responseData.session_end_time;
              }
              if (responseData.time_zone != null && responseData.time_zone != "") {
                 this.form.time_zone = responseData.time_zone;
              }
              if (responseData.time_zone_code != null && responseData.time_zone_code != "") {
                 this.form.time_zone_code = responseData.time_zone_code;
              }
              if (responseData.buffer_time != null && responseData.buffer_time != "") {
                 this.form.buffer_time = responseData.buffer_time;
              }
              if (responseData.moderator_password != null && responseData.moderator_password != "") {
                 this.form.moderator_password = responseData.moderator_password;
              }
              if (responseData.live_event_text != null && responseData.live_event_text != "") {
                 this.form.live_event_text = responseData.live_event_text;
              }
              if (responseData.certificate_id != null && responseData.certificate_id != "") {
                 this.form.certificate_id = responseData.certificate_id;
              }
              if (responseData.rewards != null && responseData.rewards != "") {
                 this.form.rewards = responseData.rewards;
              }
              if (responseData.email_link != null && responseData.email_link != "") {
                 this.form.email_link = responseData.email_link;
              }
              if (responseData.external_event != null && responseData.external_event != "") {
                 this.form.external_event = responseData.external_event;
              }
              if (responseData.redirect_away_without_registration != null && responseData.redirect_away_without_registration != "") {
                 this.form.redirect_away_without_registration = responseData.redirect_away_without_registration;
              }
              if (responseData.password != null && responseData.password != "") {
                 this.form.password = responseData.password;
              }
              if (responseData.password_placeholder != null && responseData.password_placeholder != "") {
                 this.form.password_placeholder = responseData.password_placeholder;
              }

              this.form.chat_type = responseData.chat_type;
              this.form.type = responseData.type;
              if (responseData.tnc_detail != null && responseData.tnc_detail != "") {
                 this.form.tnc_detail = responseData.tnc_detail;
              }
              if (responseData.calender_description != null && responseData.calender_description != "") {
                 this.form.calender_description = responseData.calender_description;
              }
              if (responseData.video_id != null && responseData.video_id != "") {
                 this.form.video_id = responseData.video_id;
              }
              if (responseData.vchat_id != null && responseData.vchat_id != "") {
                 this.form.vchat_id = responseData.vchat_id;
              }
              if (responseData.partner_id != null && responseData.partner_id != "") {
                 this.form.partner_id = this.fetchSinglePartner(responseData.partner_id);
              }
              if (responseData.partner_division_id != null && responseData.partner_division_id != "") {
                 this.form.partner_division_id = this.fetchSingleForum(responseData.partner_division_id);
              }
              if (responseData.community != null && responseData.community != "") {
               this.form.community_selected = responseData.community.map((c) => {
                 return {
                   id: c.community_id,
                   title: c.name,
                 };
               });
             }
             this.oldForm = _.clone(this.form);
              if (responseData.knowledge_category_id != null && responseData.knowledge_category_id != "") {
                 this.form.knowledge_category_id = responseData.knowledge_category_id;
              }
              if (responseData.experts != null && responseData.experts != "") {
                 responseData.experts.map((item) => {
                    this.fetchSingleExpert(item);
                 });
              }
              if (responseData.quiz_id != null && responseData.quiz_id != "") {
                 this.form.quiz_id = responseData.quiz_id;
              }
              if (responseData.sub_specialities != null && responseData.sub_specialities != "") {
                 this.form.sub_specialities = this.getSubSpeciality.filter((item) => {
                    if (responseData.sub_specialities.includes(item.id)) {
                       return item;
                    }
                 });
              }
              if (responseData.user_types != null && responseData.user_types != "") {
                 this.form.user_types = this.getUserType.filter((item) => {
                    if (responseData.user_types.includes(item.value)) {
                       return item;
                    }
                 });
              }
              if (responseData.speciality_to_show != null && responseData.speciality_to_show != "") {
                 this.form.speciality_to_show = this.getSpec.filter((item) => {
                    if (responseData.speciality_to_show.includes((item.id).toString())) {
                       return item;
                    }
                 });
              }
              if (responseData.multi_events != null && responseData.multi_events != "") {
                 this.getEvents = responseData.multi_events;
                 this.form.multi_events = this.getEvents.filter((item) => {
                    if (responseData.multi_events.map(t => t.id).includes(item.id)) {
                       return item;
                    }
                 });
              }
              if (responseData.type != null && responseData.type != "") {
                 this.form.type = responseData.type;
              }
              if (responseData.registration_tabs != null && responseData.registration_tabs != "") {
                 this.form.registration_tabs = responseData.registration_tabs;
                 if(!Object.keys(this.form.registration_tabs).includes('medical_registered_state')){
                    this.form.registration_tabs['medical_registered_state'] = {
                       label: "Medical Registered State",
                       active: "off",
                       required: "off",
                       placeholder: "Medical Registered State"
                    };
                 }
              }
              if (responseData.send_msg_content_thankyou != null && responseData.send_msg_content_thankyou != "") {
                 this.form.send_msg_content_thankyou = responseData.send_msg_content_thankyou;
              }
              if (responseData.payments != null && responseData.payments != "") {
                 this.form.amount = responseData.payments.amount;
                 this.form.currency = responseData.payments.currency;
              }
              if (responseData.is_non_vimeo_event != null && responseData.is_non_vimeo_event != "") {
                 this.form.video_event_type = responseData.is_non_vimeo_event;
              }
              if (responseData.video_link != null && responseData.video_link != "") {
                 this.form.video_link = responseData.video_link;
              }
              if (responseData.is_payed_event) {
                 this.form.is_payed_event = true;
              }
              if (responseData.is_protected) {
                 this.form.is_protected = true;
              }
              if (responseData.non_medical_event) {
                 this.form.non_medical_event = true;
              }
              if (responseData.is_survey_event) {
                 this.form.is_survey_event = true;
              }
              if (responseData.show_in_app) {
                 this.form.show_in_app = true;
              }
              if (responseData.is_otp_type) {
                 this.form.is_otp_type = true;
              }
              if (responseData.is_open_event) {
                 this.form.is_open_event = true;
              }
              if (responseData.hide_video) {
                 this.form.hide_video = true;
              }
              if (responseData.is_speciality) {
                 this.form.is_speciality = true;
              }
              if (responseData.survey_align) {
                 this.form.survey_align = true;
              }
              if (responseData.send_sms) {
                 this.form.send_sms = true;
              }
              if (responseData.is_user_type) {
                 this.form.is_user_type = true;
              }
              if (responseData.show_in_home) {
                 this.form.show_in_home = true;
              }
              if (responseData.send_whatsapp) {
                 this.form.send_whatsapp = true;
              }
              if (responseData.send_email) {
                 this.form.send_email = true;
                 console.log(responseData.send_email);
              }
              if (responseData.subsequent_survey) {
                 this.form.subsequent_survey = true;
              }
              if (responseData.is_mmc_number) {
                 this.form.is_mmc_number = true;
              }
              if (responseData.show_header_footer) {
                 this.form.show_header_footer = true;
              }
              if (responseData.has_certificates) {
                 this.form.has_certificates = true;
              }
              if (responseData.show_hospital_name) {
                 this.form.show_hospital_name = true;
              }
              if (responseData.meta_title != "" && responseData.meta_title != null) {
                 this.form.meta_title = responseData.meta_title;
               }

               if (responseData.meta_description != "" && responseData.meta_description != null) {
                 this.form.meta_description = responseData.meta_description;
               }

               if (responseData.meta_keywords != "" && responseData.meta_keywords != null) {
                 this.form.meta_keywords = responseData.meta_keywords;
               }
               if (responseData.live_event_country != null && responseData.live_event_country != "") {
                 if(responseData.live_event_country == 'all'){
                    this.form.live_event_country = { id: 'all', phonecode: 'all', name: 'All' };
                 }else{
                    this.form.live_event_country = this.getCountry.filter((item) => {
                       if (responseData.live_event_country == item.phonecode) {
                          return item;
                       }
                    })[0];
                 }
              }
              this.edit.banner_image_url = responseData.banner_image;
              this.edit.mobile_banner_image_url = responseData.mobile_banner_image;
              this.edit.thumbnail_image_url = responseData.thumbnail_image;
              this.edit.register_image1_url = responseData.register_image1;
              this.edit.background_image_url = responseData.background_image;
              this.edit.logo_image_url = responseData.logo_image;
              this.edit.register_image2_url = responseData.register_image2;
              this.edit.register_success_image_url = responseData.register_success_image;
              this.edit.event_completed_image_url = responseData.event_completed_image;

              if (responseData.feedback_survey != null && responseData.feedback_survey != "") {
                 this.form.feedback_survey = responseData.feedback_survey.map((item) => {
                    this.form.survey_type = item.survey_type;
                    return {
                       question: item.question,
                       feedback_type: item.feedback_type,
                       is_active: item.is_active,
                       id: item.id,
                       option: item.option,
                    }
                 })
              }
              this.liveEventId = responseData.id;
              if (responseData.send_msg_content_thankyou == 'default' || responseData.send_msg_content_thankyou == 'no_message') {
                 this.form.send_msg_content_thankyou = responseData.send_msg_content_thankyou;
              }
              else if (responseData.send_msg_content_thankyou) {
                 this.form.send_msg_content_thankyou = 'message';
                 this.form.message = responseData.send_msg_content_thankyou;
              }
              if (responseData.member_tagging != null && responseData.member_tagging != "") {
                responseData.member_tagging.map((item) => {
                  this.fetchSingleMember(item.member_id);
                });
              }
              if (responseData.view_multiplication_factor != "" && responseData.view_multiplication_factor != null) {
               if (responseData.view_multiplication_factor == 0) {
                 this.form.view_multiplication_factor = 1
               } else {
                 this.form.view_multiplication_factor =
                 responseData.view_multiplication_factor;
               }
             }
           }
        } catch (err) {
           console.log(err);
           this.$store.commit('toast/updateStatus', {
              status: true,
              icon: 'error',
              title: err.data ? err.data.message : "Please try again!"
           })
        }
        this.$store.commit('loader/updateStatus', false);
     },
     async submitData() {
        this.$store.commit('loader/updateStatus', true);
        try {
           let url = live_event.liveEventUrl;
           if (this.$route.name == 'edit-live-event') {
              url = live_event.liveEventUrl + '/' + this.liveEventId;
           }
           let dataAppend = new FormData();
           if (this.banner_image) {
              dataAppend.append('banner_image', this.banner_image);
           }
           if (this.mobile_banner_image) {
              dataAppend.append('mobile_banner_image', this.mobile_banner_image);
           }
           if (this.thumbnail_image) {
              dataAppend.append('thumbnail_image', this.thumbnail_image);
           }
           if (this.register_image1) {
              dataAppend.append('register_image1', this.register_image1);
           }
           if (this.register_image2) {
              dataAppend.append('register_image2', this.register_image2);
           }
           if (this.background_image) {
              dataAppend.append('background_image', this.background_image);
           }
           if (this.logo_image) {
              dataAppend.append('logo_image', this.logo_image);
           }
           if (this.register_success_image) {
              dataAppend.append('register_success_image', this.register_success_image);
           }
           if (this.event_completed_image) {
              dataAppend.append('event_completed_image', this.event_completed_image);
           }
           if (this.form.is_payed_event) {
              dataAppend.append("is_payed_event", 1);
           } else {
              dataAppend.append("is_payed_event", 0);
           }
           if (this.form.is_protected) {
              dataAppend.append("is_protected", 1);
           } else {
              dataAppend.append("is_protected", 0);
           }
           if (this.form.non_medical_event) {
              dataAppend.append("non_medical_event", 1);
           } else {
              dataAppend.append("non_medical_event", 0);
           }
           if (this.form.chat_type) {
              dataAppend.append("chat_type", 1);
           } else {
              dataAppend.append("chat_type", 0);
           }
           if (this.form.is_survey_event) {
              dataAppend.append("is_survey_event", 1);
           } else {
              dataAppend.append("is_survey_event", 0);
           }
           if (this.form.show_in_app) {
              dataAppend.append("show_in_app", 1);
           } else {
              dataAppend.append("show_in_app", 0);
           }
           if (this.form.is_otp_type) {
              dataAppend.append("is_otp_type", 1);
           } else {
              dataAppend.append("is_otp_type", 0);
           }
           if (this.form.is_open_event) {
              dataAppend.append("is_open_event", 1);
           } else {
              dataAppend.append("is_open_event", 0);
           }
           if (this.form.hide_video) {
              dataAppend.append("hide_video", 1);
           } else {
              dataAppend.append("hide_video", 0);
           }
           if (this.form.is_speciality) {
              dataAppend.append("is_speciality", 1);
           } else {
              dataAppend.append("is_speciality", 0);
           }
           if (this.form.survey_align) {
              dataAppend.append("survey_align", 1);
           } else {
              dataAppend.append("survey_align", 0);
           }
           if (this.form.is_mmc_number) {
              dataAppend.append("is_mmc_number", 1);
           } else {
              dataAppend.append("is_mmc_number", 0);
           }
           if (this.form.send_sms) {
              dataAppend.append("send_sms", 1);
           } else {
              dataAppend.append("send_sms", 0);
           }
           if (this.form.is_user_type) {
              dataAppend.append("is_user_type", 1);
           } else {
              dataAppend.append("is_user_type", 0);
           }
           if (this.form.show_in_home) {
              dataAppend.append("show_in_home", 1);
           } else {
              dataAppend.append("show_in_home", 0);
           }
           if (this.form.show_header_footer) {
              dataAppend.append("show_header_footer", 1);
           } else {
              dataAppend.append("show_header_footer", 0);
           }
           if (this.form.send_whatsapp) {
              dataAppend.append("send_whatsapp", 1);
           } else {
              dataAppend.append("send_whatsapp", 0);
           }
           if (this.form.send_email) {
              dataAppend.append("send_email", 1);
           } else {
              dataAppend.append("send_email", 0);
           }
           if (this.form.subsequent_survey) {
              dataAppend.append("subsequent_survey", 1);
           } else {
              dataAppend.append("subsequent_survey", 0);
           }
           if (this.form.has_certificates) {
              dataAppend.append("has_certificates", 1);
           } else {
              dataAppend.append("has_certificates", 0);
           }
           if (this.form.show_hospital_name) {
              dataAppend.append("show_hospital_name", 1);
           } else {
              dataAppend.append("show_hospital_name", 0);
           }
           if (this.form.partner_id != null && this.form.partner_id != "") {
              dataAppend.append('partner_id', this.form.partner_id.id);
           }
           if (this.form.partner_division_id != null && this.form.partner_division_id != "") {
              dataAppend.append('partner_division_id', this.form.partner_division_id.id);
           }
           if (this.form.community_selected) {
               const community_selected = this.form.community_selected.map(
                 (key) => key.id
                 );
                 community_selected.forEach((c) =>
                 dataAppend.append("community_selected[]", c)
               );
            } else {
               dataAppend.append("community_selected", [])
            }
           if (this.form.knowledge_category_id != null && this.form.knowledge_category_id != "") {
              dataAppend.append('knowledge_category_id', this.form.knowledge_category_id.id);
           }
           if (this.form.moderator_password != '') {
              dataAppend.append('moderator_password', this.form.moderator_password);
           }
           if (this.form.password != '') {
              dataAppend.append('password', this.form.password);
           }
           if (this.form.live_event_country != '' && this.form.live_event_country != null) {
              dataAppend.append('live_event_country', this.form.live_event_country.phonecode);
           }
           if (this.form.send_msg_content_thankyou == "message") {
              this.getContentType[1].value = this.form.message;
              dataAppend.append('send_msg_content_thankyou', this.form.message);
           }
           else {
              dataAppend.append('send_msg_content_thankyou', this.form.send_msg_content_thankyou);
           }

           for (var key in this.form) {
              if (key != 'partner_id' && key != 'partner_division_id' && key != 'community_selected' && key != 'speciality_to_show' && key != 'knowledge_category_id' && key != 'experts' && key != 'sub_specialities' && key != 'user_types' && key != 'registration_tabs' && key != 'type' && key != 'survey_type' && key != 'feedback_survey' && key != 'chat_type' && key != 'password' && key != 'moderator_password' && key != 'is_payed_event' && key != 'is_protected' && key != 'non_medical_event' && key != 'chat_type' && key != 'is_survey_event' && key != 'show_in_app' && key != 'is_open_event' && key != 'hide_video' && key != 'send_sms' && key != 'is_user_type' && key != 'show_in_home' && key != 'send_whatsapp' && key != 'send_email' && key != 'subsequent_survey' && key != 'has_certificates' && key != 'is_speciality' && key != 'survey_align' && key != 'send_msg_content_thankyou' && key != 'is_mmc_number' && key != 'show_header_footer' && key != 'is_otp_type' && key != 'live_event_country' && key != 'member_tagging') {
                 dataAppend.append(key, this.form[key]);
              }
           }
           const user_types = this.form.user_types.map((key) => key.value);
           const experts = this.form.experts.map((key) => key.id);
           const sub_specialities = this.form.sub_specialities.map((key) => key.id);
           const live_multi_events = this.form.multi_events.map((key) => key.id);
           if(live_multi_events.length > 0){
              dataAppend.delete('multi_events');
              live_multi_events.forEach(u => dataAppend.append("multi_events[]", u));
           }
           const specialities = this.form.speciality_to_show.map((key) => key.id);

           specialities.forEach(s => dataAppend.append("speciality_to_show[]", s));
           user_types.forEach(u => dataAppend.append("user_types[]", u));
           experts.forEach(e => dataAppend.append("experts[]", e));
           sub_specialities.forEach(s => dataAppend.append("sub_specialities[]", s));

           dataAppend.append('registration_tabs', JSON.stringify(this.form.registration_tabs));
           dataAppend.append('feedback_survey', JSON.stringify(this.form.feedback_survey));
           dataAppend.append('type', 'tabs');
           dataAppend.append('survey_type', this.form.survey_type);
           if (this.form.member_tagging) {
            const memberTagging = this.form.member_tagging.map((key) => key.member_id);
            memberTagging.forEach((e) => dataAppend.append("member_tagging[]", e));
          }

           if (this.$route.name == 'edit-live-event') {
              dataAppend.append('_method', 'put')
           }
           const data = await this.postRequest(url, dataAppend)
           if (data.status) {
              this.$store.commit('toast/updateStatus', {
                 status: true,
                 icon: 'success',
                 title: data.message
              });
              this.$router.push('/live-event')
           }
        } catch (err) {
           this.$store.commit('toast/updateStatus', {
              status: true,
              icon: 'error',
              title: err.data ? err.data.message : "Please try again!",
           })
        }
        this.$store.commit('loader/updateStatus', false);
     },
     async generateCertificate(id) {
        const url = live_event.generateCertificate + '/' + id;
        const data = await this.getRequest(url);
        if (data.status) {
           this.$store.commit('toast/updateStatus', {
              status: true,
              icon: 'success',
              title: data.message
           });
        }
     },
     async regenerateCertificate(id) {
      const url = live_event.regenerateCertificate + '/' + id;
      const data = await this.getRequest(url);
      if (data.status) {
         this.$store.commit('toast/updateStatus', {
            status: true,
            icon: 'success',
            title: data.message
         });
      }
   },
     gotomoderator(id){
        const encode = btoa(id);
        window.open(this.$hostLink + '/event/moderator/' + encode);
     },
     async download(id) {
        const url = live_event.download + '/' + id;
        const data = await this.getRequest(url);
        if (data.status) {
           this.$store.commit('toast/updateStatus', {
              status: true,
              icon: 'success',
              title: data.message
           });
        }
     },
     async sendEmail(id) {
        const url = live_event.sendEmail + '/' + id;
        const data = await this.getRequest(url);
        if (data.status) {
           this.$store.commit('toast/updateStatus', {
              status: true,
              icon: 'success',
              title: data.message
           });
        }
     },
     async getCertificate(id){
         const url = live_event.getCertificates + '/' + id;
         
         const data = await this.getRequest(url);
         if (data.status) {
            const responseData = data.response;
            this.base_s3_url_certificate = responseData.original.base_s3_url;
            // console.log('s3',this.base_s3_url_certificate);
            this.certificate = responseData.original;
            this.memberCertificates = responseData.original.memberCertificates.data;
            this.$store.commit('toast/updateStatus', {
            status: true,
            icon: 'success',
            title: data.message
            });
         }
   },
   downloadCertificate(file) {
      const link = document.createElement('a');
      link.href = this.base_s3_url_certificate +'/'+ file + '.png'; // Replace with the actual file URL
      link.download = file+'.png';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  watch: {
      status(v) {
         if (v == 1) {
            this.fetchAllCommunity();
         } else {
         if (this.$route.name == "add-live-event") {
            this.form.community_selected = [];
         } else if (this.$route.name == "edit-live-event") {
            this.form.community_selected = this.oldForm.community_selected;
         }
         }
      },
     currentPage: {
        handler: function (value) {
           if (value) {
              this.params = `&page=${value}`;
              this.fetchData(this.activeTab);
              this.pageNo = this.currentPage;
           }
        },
     },
     activeTab:{
        handler: function (value) {
           if(value == 'trash'){
              let index = this.fields.findIndex((item) => item.key == "delete");
              this.fields.splice(index,1);
              let edit_index = this.fields.findIndex((item) => item.key == "edit");
              this.fields.splice(edit_index,1);
           }else if(value == 'all' || value == 'active'){
              if(this.fields[this.fields.length-1].key != 'delete'){
                 this.fields.splice(this.fields.length,0,{key : "edit"});
                 this.fields.splice(this.fields.length,0,{key : "delete"});
              }
           }
        }
     },
     "form.is_payed_event"(v) {
        if (v) {
           this.form.is_payed_event = true;
        } else {
           this.form.is_payed_event = false;
        }
     },
     "form.is_protected"(v) {
        if (v) {
           this.form.is_protected = true;
        } else {
           this.form.is_protected = false;
        }
     },
     "form.non_medical_event"(v) {
        if (v) {
           this.form.non_medical_event = true;
        } else {
           this.form.non_medical_event = false;
        }
     },
     "form.is_survey_event"(v) {
        if (v) {
           this.form.is_survey_event = true;
        } else {
           this.form.is_survey_event = false;
        }
     },
     "form.show_in_app"(v) {
        if (v) {
           this.form.show_in_app = true;
        } else {
           this.form.show_in_app = false;
        }
     },
     "form.is_otp_type"(v) {
        if (v) {
           this.form.is_otp_type = true;
        } else {
           this.form.is_otp_type = false;
        }
     },
     "form.is_open_event"(v) {
        if (v) {
           this.form.is_open_event = true;
        } else {
           this.form.is_open_event = false;
        }
     },
     "form.hide_video"(v) {
        if (v) {
           this.form.hide_video = true;
        } else {
           this.form.hide_video = false;
        }
     },
     "form.is_speciality"(v) {
        if (v) {
           this.form.is_speciality = true;
        } else {
           this.form.is_speciality = false;
        }
     },
     "form.survey_align"(v) {
        if (v) {
           this.form.survey_align = true;
        } else {
           this.form.survey_align = false;
        }
     },
     "form.is_mmc_number"(v) {
        if (v) {
           this.form.is_mmc_number = true;
        } else {
           this.form.is_mmc_number = false;
        }
     },
     "form.send_sms"(v) {
        if (v) {
           this.form.send_sms = true;
        } else {
           this.form.send_sms = false;
        }
     },
     "form.is_user_type"(v) {
        if (v) {
           this.form.is_user_type = true;
        } else {
           this.form.is_user_type = false;
        }
     },
     "form.show_in_home"(v) {
        if (v) {
           this.form.show_in_home = true;
        } else {
           this.form.show_in_home = false;
        }
     },
     "form.show_header_footer"(v) {
        if (v) {
           this.form.show_header_footer = true;
        } else {
           this.form.show_header_footer = false;
        }
     },
     "form.send_whatsapp"(v) {
        if (v) {
           this.form.send_whatsapp = true;
        } else {
           this.form.send_whatsapp = false;
        }
     },
     "form.send_email"(v) {
        if (v) {
           this.form.send_email = true;
        } else {
           this.form.send_email = false;
        }
     },
     "form.has_certificates"(v) {
        if (v) {
           this.form.has_certificates = true;
        } else {
           this.form.has_certificates = false;
        }
     },
     "form.show_hospital_name"(v) {
        if (v) {
           this.form.show_hospital_name = true;
        } else {
           this.form.show_hospital_name = false;
        }
     }
  },
  mounted() {
     if (this.$route.name == 'edit-live-event' || this.$route.name == 'add-live-event') {
        this.fetchSubSpeciality();
        this.fetchUserType();
        this.fetchSpec();
        this.fetchKnowledgeCategory();
        this.fetchQuiz();
        this.fetchCountry();
        if (this.$route.name == 'edit-live-event') {
           this.fetchLiveEvent(this.$route.params.id);
        }
     } else {
        this.fetchData(this.activeTab);
     }

     if(this.$route.name == 'show-certificates'){
         this.getCertificate(this.$route.params.id);
     }
  }
};